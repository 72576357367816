import { Injectable } from '@angular/core'
import {
  LoggerService,
  MessageService,
  SharedPermissionService,
  SharedUserGuard,
  SharedUserService,
} from '@agroone-front/shared'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class UserGuard extends SharedUserGuard {
  constructor(
    override readonly logger: LoggerService,
    override readonly messageService: MessageService,
    override readonly translate: TranslateService,
    override readonly userService: SharedUserService,
    override readonly permissionService: SharedPermissionService
  ) {
    super(logger, messageService, translate, userService, permissionService)
  }
}
