import { Component, Inject, OnInit } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms'
import { ConstantLite, Crop, User } from '@agroone/entities'
import { FormElement } from '@agroone-app/shared/form/models/form-element.model'
import { endOfDay, format, isWithinInterval, startOfDay } from 'date-fns'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CropFilters, CropListFilters } from '@agroone-app/shared/models'
import { SharedConstantService, SharedUserService } from '@agroone-front/shared'
import { DateFormats } from '@agroone/dates'

@UntilDestroy()
@Component({
  selector: 'app-crop-filters',
  templateUrl: './crop-filters.component.html',
  styleUrls: ['./crop-filters.component.sass'],
})
export class CropFiltersComponent implements OnInit {
  public form: FormGroup
  public growers: string[] = []
  public fields: string[] = []
  public cropTypes: ConstantLite[] = []
  public varieties: string[] = []
  public technicians: User[] = []
  public scoutingDateElement: FormElement
  public maxPlantedDate = new Date()
  public maxLatestScoutingDate = new Date()

  get cropType(): AbstractControl {
    return this.form.get('cropType')
  }

  get growerName(): AbstractControl {
    return this.form.get('growerName')
  }

  get technicianName(): AbstractControl {
    return this.form.get('technicianName')
  }

  get fieldName(): AbstractControl {
    return this.form.get('fieldName')
  }

  get cropName(): AbstractControl {
    return this.form.get('cropName')
  }

  get varietyName(): AbstractControl {
    return this.form.get('varietyName')
  }

  get dateRange(): FormGroup {
    return this.form.get('dateRange') as FormGroup
  }

  get plantedDateRange(): AbstractControl {
    return this.form.get('plantedDateRange')
  }

  get floweringScoutingId(): AbstractControl {
    return this.form.get('floweringScoutingId')
  }

  get growthStageScoutingId(): AbstractControl {
    return this.form.get('growthStageScoutingId')
  }

  get yieldEstimationScoutingId(): AbstractControl {
    return this.form.get('yieldEstimationScoutingId')
  }

  get lastScoutingDate(): AbstractControl {
    return this.form.get('lastScoutingDate')
  }

  get hasOrganicField(): AbstractControl {
    return this.form.get('hasOrganicField')
  }

  constructor(
    public dialogRef: MatDialogRef<CropFiltersComponent, CropListFilters>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filters: CropFilters
      crops: Crop[]
    },
    private formBuilder: FormBuilder,
    public constantService: SharedConstantService,
    public userService: SharedUserService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      cropType: [
        {
          value: this.constantService.croptypes.find((x) => x.code === this.data?.filters.cropType?.value),
          disabled: this.data?.filters.cropType?.disabled,
        },
      ],
      growerName: [
        {
          value: this.data?.filters.growerName?.value,
          disabled: this.data?.filters.growerName?.disabled,
        },
      ],
      technicianName: [
        {
          value: this.data?.filters.technicianName?.value,
          disabled: this.data?.filters.technicianName?.disabled,
        },
      ],
      cropName: [
        {
          value: this.data?.filters.cropName?.value,
          disabled: this.data?.filters.cropName?.disabled,
        },
      ],
      fieldName: [
        {
          value: this.data?.filters.fieldName?.value,
          disabled: this.data?.filters.fieldName?.disabled,
        },
      ],
      varietyName: [
        {
          value: this.data?.filters.varietyName?.value,
          disabled: this.data?.filters.varietyName?.disabled,
        },
      ],
      plantedDateRange: [
        {
          value: this.data?.filters?.dateRange?.start
            ? [
                new Date(this.data?.filters.dateRange?.start),
                this.data?.filters.dateRange?.end !== null && this.data?.filters.dateRange?.end !== ''
                  ? new Date(this.data?.filters.dateRange?.end)
                  : null,
              ]
            : undefined,
          disabled: this.data?.filters.dateRange?.disabled,
        },
      ],
      floweringScoutingId: [
        {
          value: this.data?.filters.doesNotHasFloweringScoutingId?.value,
          disabled: this.data?.filters.doesNotHasFloweringScoutingId?.disabled,
        },
      ],
      growthStageScoutingId: [
        {
          value: this.data?.filters.doesNotHasGrowthStageScoutingId?.value,
          disabled: this.data?.filters.doesNotHasGrowthStageScoutingId?.disabled,
        },
      ],
      yieldEstimationScoutingId: [
        {
          value: this.data?.filters.doesNotHasYieldEstimationScoutingId?.value,
          disabled: this.data?.filters.doesNotHasYieldEstimationScoutingId?.disabled,
        },
      ],
      lastScoutingDate: [
        {
          value: this.data?.filters.lastScoutingDate?.value,
          disabled: this.data?.filters.lastScoutingDate?.disabled,
        },
      ],
      hasOrganicField: [
        {
          value: this.data?.filters.isOrganic?.value,
          disabled: this.data?.filters.isOrganic?.disabled,
        },
      ],
    })

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.updateLists(form)
    })

    this.updateLists(this.form.value)
  }

  save() {
    const start = this.plantedDateRange?.value
      ? format(new Date(this.plantedDateRange?.value[0]), DateFormats.YYYY_MM_DD)
      : undefined

    const end = this.plantedDateRange?.value
      ? this.plantedDateRange.value[1] !== null
        ? format(new Date(this.plantedDateRange?.value[1]), DateFormats.YYYY_MM_DD)
        : undefined
      : undefined

    const filters: CropListFilters = {
      growerName: this.growerName.value,
      technicianName: this.technicianName.value,
      fieldName: this.fieldName.value,
      cropName: this.cropName.value,
      cropType: this.cropType.value?.code,
      varietyName: this.varietyName.value,
      dateRange: { start, end },
      doesNotHasFloweringScoutingId: this.floweringScoutingId.value,
      doesNotHasGrowthStageScoutingId: this.growthStageScoutingId.value,
      doesNotHasYieldEstimationScoutingId: this.yieldEstimationScoutingId.value,
      lastScoutingDate: this.lastScoutingDate.value
        ? format(new Date(this.lastScoutingDate.value), DateFormats.YYYY_MM_DD)
        : undefined,
      isOrganic: this.hasOrganicField.value,
    }
    this.dialogRef.close(filters)
  }

  public display(value: string): string {
    return value
  }

  public filter(value: string | null, input: string | null): boolean {
    if (!input) {
      return true
    }
    const filterValue: string = input.toLowerCase()
    return value?.toLowerCase().indexOf(filterValue) !== -1
  }

  private updateLists(filters: {
    growerName: string
    technicianName: string
    fieldName: string
    cropType: string
    varietyName: string
    dateRange: { start: string; end: string }
  }) {
    filters.cropType = this.cropType.value?.code
    if (!this.data.crops?.length) {
      return
    }
    const growers = []
    const fields = []
    let cropTypes = []
    const varieties = []
    const technicians = []

    for (const crop of this.data.crops) {
      const growerNameIsOk = !filters.growerName || crop.growerName === filters.growerName
      const fieldNameIsOk = !filters.fieldName || crop.fieldName === filters.fieldName
      const cropTypeIsOk = !filters.cropType || crop.cropType === filters.cropType
      const varietyNameIsOk = !filters.varietyName || crop.varietyName === filters.varietyName
      const technicianNameIsOk = !filters.technicianName || crop.technician === filters.technicianName

      const dateIsInRange =
        !crop.plantedDateAsDate ||
        !filters.dateRange?.start ||
        !filters.dateRange?.end ||
        isWithinInterval(startOfDay(new Date(crop.plantedDateAsDate)), {
          start: startOfDay(new Date(filters.dateRange.start)),
          end: endOfDay(new Date(filters.dateRange.end)),
        })

      if (fieldNameIsOk && cropTypeIsOk && varietyNameIsOk && dateIsInRange && technicianNameIsOk) {
        growers.push(crop.growerName)
      }
      if (growerNameIsOk && cropTypeIsOk && varietyNameIsOk && dateIsInRange && technicianNameIsOk) {
        fields.push(crop.fieldName)
      }
      if (growerNameIsOk && fieldNameIsOk && varietyNameIsOk && dateIsInRange && technicianNameIsOk) {
        cropTypes.push(crop.cropType)
      }
      if (growerNameIsOk && fieldNameIsOk && varietyNameIsOk && dateIsInRange && cropTypeIsOk) {
        technicians.push(crop.technician)
      }
      if (growerNameIsOk && fieldNameIsOk && cropTypeIsOk && dateIsInRange && technicianNameIsOk && crop.varietyName) {
        varieties.push(crop.varietyName)
      }
    }
    cropTypes = [...new Set([...cropTypes])]
    this.technicians = [...new Set([...technicians])].sort()
    this.growers = [...new Set([...growers])].sort((a, b) => a.localeCompare(b))
    this.fields = [...new Set([...fields])].sort((a, b) => a.localeCompare(b))
    this.cropTypes = this.constantService.croptypes.filter((c) => cropTypes?.includes(c.code))
    this.varieties = [...new Set([...varieties])].sort((a, b) => a.localeCompare(b))
  }

  public resetCropname() {
    this.cropName.setValue('')
    this.form.markAsDirty()
  }
}
