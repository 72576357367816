import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FileService } from '@agroone-app/core/file/file.service'
import { MapOptions } from '@agroone-app/shared/dialog/models/mapOptions.model'
import { FeatureCollection } from '@agroone/entities'
import { MapService } from '@agroone-app/shared/map/lib/map.service'
import { UntilDestroy } from '@ngneat/until-destroy'
import { SharedUserService } from '@agroone-front/shared'

@UntilDestroy()
@Component({
  selector: 'app-map-draw',
  templateUrl: './map-draw.component.html',
  styleUrls: ['./map-draw.component.sass'],
})
export class MapDrawComponent implements OnInit {
  @Input() data: MapOptions

  @Output() closeDialog = new EventEmitter()

  @ViewChild('mapcontainer') mapContainer: ElementRef

  private initThen: Promise<void>

  public isFetchingData = true
  public hasBoundaries = false

  constructor(
    private mapService: MapService,
    private fileService: FileService,
    private userService: SharedUserService
  ) {}

  ngOnInit() {
    this.initThen = this.initMap(this.data)
  }

  async setBoundary() {
    if (this.initThen) {
      await this.initThen
    }
    const boundary = this.mapService.getBoundary()
    this.closeDialog.emit({ boundary, event: 'close' })
  }

  public async import(event: Event) {
    const fileList: FileList = (event?.target as HTMLInputElement)?.files
    if (fileList) {
      // Read the files (zip, shp or kml)
      const files = await this.fileService.filesToKmlOrShapefile(Array.from(fileList))

      // Extract the feature collection
      const collection = await this.mapService.extract(files.shp[0] ?? files.kml[0])

      this.mapService.resetMap()

      // Re-init the map with the new boundary
      this.initThen = this.initMap({
        ...this.data,
        boundary: collection as FeatureCollection,
      })
    }
  }

  private async initMap(data: MapOptions) {
    if (data.location || data.boundary) {
      await this.mapService.initMap('map', this.mapContainer, data.location, data.boundary, {
        color: data.style,
        drawable: data.displayMode === 'draw',
        stencil: data.stencil,
      })
    } else {
      const address = !data.address || data.address === '' ? this.userService.currentUser.regionName : data.address

      await this.mapService.initMapByAddress('map', this.mapContainer, address, {
        color: data.style,
        drawable: data.displayMode === 'draw',
        stencil: data.stencil,
      })
    }

    this.isFetchingData = false
  }
}
